<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <div class="fill-width d-flex justify-space-between flex-wrap">
                    <div class="mr-2"><v-btn text :to="{name: 'admin.user-reports.index'}">return</v-btn></div>
                </div>
            </v-card-title>
            <v-card-text>
                <v-form v-if="report">
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.reporter.id" label="Reporter ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.reporter.name" label="Reporter" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea :value="report.accusation" label="Accusation" disabled></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.dependant.id" label="Dependant ID" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="report.dependant.name" label="Dependant" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="report.dependant.blocked_at">
                        <v-col>
                            <v-text-field :value="moment(report.dependant.blocked_at).format('LLL')" label="Blocked" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :value="moment(report.created_at).format('LLL')" label="Created" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn v-if="report.dependant.blocked_at" class="error" @click="openDialog">Unblock Dependant</v-btn>
                            <v-btn v-else class="error" @click="openDialog">Block Dependant</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <Dialog @no="closeDialog" @yes="handleBlock" v-if="dialog">{{
                $t('dialogs.are_you_sure')
            }}
        </Dialog>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import UserReport from "@/models/admin/UserReport";
    import Dialog from "@/components/app/setting/YesNoDialog";
    import {mapActions, mapMutations} from "vuex";

    export default {
        name: "user-reports-show",
        components: {
            AdminDashboardLayout,
            Dialog,
        },
        data: function () {
            return {
                report: false,
                dependant: false,
                errors: {},
                dialog: false,
            }
        },
        methods: {
            ...mapMutations(['showSnackBar']),
            ...mapActions([
                'AdminBlockUser',
            ]),
            async handleBlock() {
                this.closeDialog()
                this.AdminBlockUser(this.report.dependant.id).then(async () => {
                    this.report = await UserReport.include(['reporter', 'dependant'])
                        .find(this.$route.params.id)
                })
            },
            openDialog() {
                this.dialog = true
            },
            closeDialog() {
                this.dialog = false
            },
        },
        async mounted() {
            const reportId = this.$route.params.id
            this.report = await UserReport.include(['reporter', 'dependant'])
                .find(reportId)
        },
    }
</script>

<style scoped>

</style>
